<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-14 13:40:59
 * @LastEditTime: 2021-03-22 10:00:59
 * @LastEditors: yangliao
 * @Description: 立即抄表
 * @FilePath: /netHallOfficialAccounts/src/views/SelfMeterRead/selfMeterReadImmediately.vue
-->
<template>
    <div class="selfMeterReadImmediately-page">
        <div class="immediately-topContent">
            <em>地址</em>
            <div class="adressSpan">
                <p>{{dataInfo.userAddress}}</p>
                <!-- <p>{{dataInfo.address2}}</p> -->
            </div>
        </div>
        <div class="immediately-centerContent">
            <div class="preRecord">
                <span>上次读数 <i>{{dataInfo.lastTimeRead}}</i></span>
                <em>{{dataInfo.lastReadTime}}</em>
            </div>
            <div class="readRecord">
                <van-form>
                    <van-field
                        v-model="currentRead"
                        placeholder="填写当前读数"
                        type="digit"
                        maxlength="10"
                        :rules="[{ validator: validatorNumber, message: '' }]"
                    />
                </van-form>
            </div>
            <div class="readInfo">
                <span>请仔细查看水表后填写</span>
                <em @click="showExample">查看示例</em>
            </div>
        </div>
        <div class="immediately-bottomContent">
            <div class="txtInfo">
                <span>水表拍照</span>
            </div>
            <div class="photo">
                <van-field name="uploader">
                    <template #input>
                        <van-uploader v-model="uploader" :max-count="1" :after-read="afterRead" @delete="deleteValue" />
                    </template>
                </van-field>
            </div>
            <div class="txtInfo">
                <span>请对准水表拍摄，如环境较暗请开闪光灯</span>
            </div>
        </div>
        <div class="btnContent">
            <van-button type="info" block @click="submitContent">提交</van-button>
        </div>

        <!-- 弹出框 -->
        <van-popup v-model="show" :style="{ height: '100%', width: '100%' }">
            <div class="dialogContent" @click="show = false"></div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
// import exampleImg from '../../assets/background/example.png';
import { selfMeterReadInfo, uploadPictureBase64, readInfo } from '../../api/home';

export default {
    name: 'SelfMeterReadImmediately',
    data() {
        return {
            show: false,
            dataInfo: {},
            readRecordValue: '',
            uploader: [],
            currentRead: '',
            imgId: '',
            // exampleImg,
            index: this.$route.query.index,
        }
    },
    mounted() {
        this.selfMeterReadInfo()
    },
    methods: {
        async selfMeterReadInfo() {
            const {status, resultData} = await selfMeterReadInfo();
            if (status === 'complete') {
                this.dataInfo = resultData[this.index];
                // console.log('数据--', this.dataInfo)
            }
        },
        validatorNumber(val) {
            if (Number(val) < Number(this.dataInfo.lastTimeRead)) {
                return Toast('当前读数不能小于上次读数')
            }
        },
        showExample() {
            this.show = true
        },
        // 此时可以自行将文件上传至服务器
        async afterRead(file) {
            let suffix = file.file.name.split(".")[1];
            let arg = {
                endWith: suffix,
                imageString: file.content,
                returnFlag: false,
                pictureRealName:file.file.name
            }
            const {status, resultData} = await uploadPictureBase64(arg);
            if (status === 'complete') {
                this.imgId = resultData
            }
        },
        // 删除文件
        deleteValue() {
            this.imgId = ""
        },
        async submitContent() {
            const { accountMonth, lastTimeRead, readCycle, userNo } = this.dataInfo
            // 比较
            if (Number(this.currentRead) < Number(lastTimeRead)) {
                return Toast('当前读数不能小于上次读数')
            }
            if (this.imgId) {
                let arg = {
                    accountMonth: accountMonth,
                    currentMeterImage: this.imgId,
                    currentRead: this.currentRead,
                    lastTimeRead: lastTimeRead,
                    readCycle: readCycle,
                    userCode: userNo
                }
                const {status, resultData} = await readInfo(arg);
                if (status === 'complete') {
                    this.$router.push({ name: 'SelfMeterRead', path: '/SelfMeterRead' })
                }
            } else {
                return Toast('请上传水表照片')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.selfMeterReadImmediately-page {
    .immediately-topContent {
        margin: 16px;
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        em {
            font-style: normal;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
        }
        .adressSpan {
            p {
                margin: 0px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                text-align: right;
                line-height: 22px;
            }
        }
        /deep/.van-field__control {
            text-align: right;
        }
    }
    .immediately-centerContent {
        margin: 16px;
        border-radius: 8px;
        background: #ffffff;
        padding-left: 16px;
        padding-right: 16px;
        .preRecord {
            // border-bottom: 1px solid rgba(0,0,0,0.08);
            position: relative;
            height: 49px;
            line-height: 49px;
            display: flex;
            justify-content: space-between;
            span {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.45);
                i {
                    font-style: normal;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: rgba(0,0,0,0.85);
                }
            }
            em {
                font-style: normal;
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: rgba(0,0,0,0.45);
                text-align: center;
            }
        }
        .preRecord::after {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 0px;
            bottom: 0;
            left: 0px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
        .readRecord {
            position: relative;
            /deep/.van-field__control {
                text-align: center;
                height: 49px;
                line-height: 49px;
                font-family: PingFangSC-Regular;
                font-size: 24px;
            }
            /deep/.van-field__error-message {
                text-align: center;
            }
        }
        .readRecord::after {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 0px;
            bottom: 0;
            left: 0px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
        .readInfo {
            display: flex;
            justify-content: space-between;
            height: 48px;
            line-height: 48px;
            span {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.45);
            }
            em {
                font-style: normal;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #3774BC;
            }
        }
    }
    .immediately-bottomContent {
        margin: 16px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;
        .txtInfo {
            position: relative;
            padding-left: 16px;
            height: 50px;
            line-height: 50px;
            span {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.45);
            }
        }
        .txtInfo::after{
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 16px;
            bottom: 0;
            left: 16px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
        .txtInfo::before {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 16px;
            top: 0;
            left: 16px;
            border-top: 1px solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
        // .txtInfo:last-child::after {
        //     position: absolute;
        //     box-sizing: border-box;
        //     content: ' ';
        //     pointer-events: none;
        //     right: 16px;
        //     top: 0;
        //     left: 16px;
        //     border-top: 0px solid transparent;
        //     -webkit-transform: scaleY(.5);
        //     transform: scaleY(.5);
        // }
        .photo {
            /deep/.van-uploader {
                margin: 0  auto;
            }
            /deep/.van-cell__value {
                background: rgba(246,246,246,0.50);
            }
            /deep/.van-uploader__upload {
                background: rgba(246,246,246,0.50);
            }
        }
    }
    .btnContent {
        padding-left: 16px;
        padding-right: 16px;
        /deep/.van-button--block {
            width: 90%;
            position: fixed;
            bottom: 10px;
            border-radius: 8px;
        }
    }
    /deep/.van-popup {
        width: 80%;
        height: 60%;
    }
    .dialogContent {
        // margin-top: 12%;
        width: 100%;
        height: 440px;
        background: url('../../assets/background/example_new.png') no-repeat 3px -50px;
        background-size: cover;
    }
}
</style>
